import { useState } from "react";
import Heart3D from "../components/Heart3D";
import ConfettiEffect from "../components/ConfettiEffect";
import RaindropEffect from "../components/RaindropEffect";
import VideoModal from "../components/VideoModal";
import "./ValentinePage.css";

const ValentinePage = () => {
  const [response, setResponse] = useState(null);
  const [noButtonPosition, setNoButtonPosition] = useState({ top: "50%", left: "50%" });
  const [yesButtonScale, setYesButtonScale] = useState(1); // To control the size of the "Yes" button
  const [showVideo, setShowVideo] = useState(false); // State to track if the video modal is shown

  // Function to generate random position
  const getRandomPosition = () => {
    const randomTop = Math.random() * 80 + 10; // Random top between 10% and 90%
    const randomLeft = Math.random() * 80 + 10; // Random left between 10% and 90%
    return { top: `${randomTop}%`, left: `${randomLeft}%` };
  };

  // Update the button position on hover
  const handleMouseEnterNoButton = () => {
    setNoButtonPosition(getRandomPosition());
    setYesButtonScale(10.2); // Increase size of the "Yes" button
  };

  // State to track the timeout ID
  const [timeoutId, setTimeoutId] = useState(null);

  // Reset the "Yes" button size after a delay
  const handleMouseLeaveNoButton = () => {
    // Clear any existing timeout (to prevent multiple timeouts from stacking)
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout to reset the button size after 2 seconds
    const newTimeoutId = setTimeout(() => {
      setYesButtonScale(1); // Reset size
    }, 500); // 2000ms = 2 seconds

    // Store the timeoutId to clear it if necessary
    setTimeoutId(newTimeoutId);
  };

  // Function to handle the "Yes" button click
  const handleYesClick = () => {
    setResponse("yes");
    setTimeout(() => {
      setShowVideo(true); // Show the video after the confetti
    }, 2000); // Adjust the delay time as needed
  };

  const handleCloseVideo = () => {
    setShowVideo(false); // Close the video modal when the user clicks the close button
  };

  return (
    <div className="valentine-container">
      {response === "yes" && <ConfettiEffect />}
      {response === "no" && <RaindropEffect />}

      <h1 className="valentine-text">Will you be my Valentine? ❤️</h1>

      {/* Multiple Hearts Rendered */}
      <div className="heart-container">
        <Heart3D />
      </div>

      <div className="buttons">
        <button
          className="yes-button"
          onClick={handleYesClick}
          style={{ transform: `scale(${yesButtonScale})` }}
        >
          Yes 😍
        </button>
        <button
          className="no-button"
          onClick={() => setResponse("no")}
          onMouseEnter={handleMouseEnterNoButton} // Trigger random position and size change
          onMouseLeave={handleMouseLeaveNoButton} // Reset size when hover ends
          style={{ top: noButtonPosition.top, left: noButtonPosition.left }} // Apply random position
        >
          No 😢
        </button>
      </div>

      {/* Show Video Modal if triggered */}
      {showVideo && (
        <VideoModal
          videoUrl="https://www.youtube.com/embed/ZHgyQGoeaB0?si=p1XfY5wLVfjLKnlm" // Replace with your YouTube video URL
          onClose={handleCloseVideo}
        />
      )}
    </div>
  );
};

export default ValentinePage;
