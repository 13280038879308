import { useEffect, useState } from "react";

const RaindropEffect = () => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    setTimeout(() => setShow(false), 5000);
  }, []);

  return show ? (
    <div className="absolute inset-0 bg-blue-500 bg-opacity-30 flex items-center justify-center">
      <p className="text-white text-2xl">😢 Don't break my heart...</p>
    </div>
  ) : null;
};

export default RaindropEffect;
