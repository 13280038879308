import { useState } from "react";
import "./VideoModal.css";

const VideoModal = ({ videoUrl, onClose }) => {
  return (
    <div className="video-modal-overlay">
      <div className="video-modal">
        <button className="close-button" onClick={onClose}>
          ✖
        </button>
        <iframe
          className="video-frame"
          width="560"
          height="315"
          src={videoUrl}
          title="YouTube video"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default VideoModal;
