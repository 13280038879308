import { Canvas, useFrame } from "@react-three/fiber";
import { useRef } from "react";
import { Shape, ExtrudeGeometry } from "three";
import { OrbitControls } from "@react-three/drei";

const Heart3D = () => {
  return (
    <Canvas camera={{ position: [0, 0, 5], fov: 50 }}>
      <ambientLight intensity={0.8} />
      <pointLight position={[5, 5, 5]} intensity={1} />
      <Heart />
      <OrbitControls enableZoom={false} />
    </Canvas>
  );
};

const Heart = () => {
  const meshRef = useRef();

  // Rotate the heart automatically
  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.rotation.y += 0.01;  // Adjust the speed of rotation
    }
  });

  const heartShape = new Shape();
  heartShape.moveTo(0, 0.3);
  heartShape.bezierCurveTo(0.3, 0.5, 0.6, 0.2, 0.3, -0.2);
  heartShape.bezierCurveTo(0, -0.5, -0.3, -0.2, -0.3, -0.2);
  heartShape.bezierCurveTo(-0.6, 0.2, -0.3, 0.5, 0, 0.3);
  const extrudeSettings = {
    depth: 0.15,
    bevelEnabled: true,
    bevelSize: 0.01,
    bevelThickness: 0.01,
  };

  return (
    <mesh
      ref={meshRef}
      position={[0, 0, 0]}
      scale={[4, 4, 4]}  // Larger size
      rotation={[0, Math.PI, 0]}  // Rotate so the face of the heart is visible
    >
      <extrudeGeometry args={[heartShape, extrudeSettings]} />
      <meshStandardMaterial color="red" metalness={0.4} roughness={0.2} />
    </mesh>
  );
};

export default Heart3D;
