import { useState, useEffect } from "react";
import "./LockedScreen.css"; // Import the CSS file

const LockedScreen = ({ onUnlock }) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [seconds, setSeconds] = useState(0);

  const relationshipStartDate = new Date("2022-01-01T00:00:00Z"); // Replace with the actual date you became a couple

  // Calculate the number of seconds since the relationship start date
  useEffect(() => {
    const interval = setInterval(() => {
      const currentDate = new Date();
      const timeDiff = currentDate - relationshipStartDate;
      setSeconds(Math.floor(timeDiff / 1000)); // Convert milliseconds to seconds
    }, 1000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [relationshipStartDate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password.toLowerCase() === "beeba") {
      onUnlock();
    } else {
      setError(true);
      setPassword("");
      setTimeout(() => setError(false), 3000);
    }
  };

  return (
    <div className="lock-screen">
      <div className={`lock-box ${error ? "shake" : ""}`}>
        <h2>Enter the Secret Password ❤️</h2>

        {/* Relationship counter at the top */}
        <div className="relationship-counter">
          <p>Our relationship has been going for:</p>
          <p className="seconds-counter">{seconds} seconds</p>
        </div>

        <form onSubmit={handleSubmit}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="password-input"
            placeholder="Enter password"
          />
          {error && <p className="error-text">Wrong password! Try again. (Hint it is one of our names)</p>}
          <button type="submit" className="unlock-button">Unlock</button>
        </form>
      </div>
    </div>
  );
};

export default LockedScreen;
