import { useState } from "react";
import LockedScreen from "./pages/LockedScreen";
import ValentinePage from "./pages/ValentinePage";

function App() {
  const [unlocked, setUnlocked] = useState(false);

  return (
    <div className="App">
      {unlocked ? <ValentinePage /> : <LockedScreen onUnlock={() => setUnlocked(true)} />}
    </div>
  );
}

export default App;
